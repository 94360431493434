



































































.buyer {
  &__subtitle {
    margin-top: 15px;
  }
}











































































.project-actions {
  margin-left: 10px;
}

























.project-box {
  &__links {
    list-style: none;
    padding: 0;
  }
}












































































@import '../../utilities/config';
@import '../../utilities/table/table';

.construction-schedule::before {
  position: absolute;
  content: '';
  transform: translate(-150%);
  height: 18px;
  width: 18px;
  background-image: url(../../assets/calendar_dark_green.svg);
  background-size: 1.3em 1.3em;
}

$header-background: rgb(235, 235, 237);
$border-color: rgb(201, 201, 201);

.building-list {
  &__table {
    border-collapse: separate;
    border-spacing: 0;

    .center-align {
      text-align: center;
    }

    .right-align {
      text-align: right;
    }

    tr {
      border: 2px solid $border-color;
    }

    thead tr {
      background-color: $header-background;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      th {
        border: 0;
        color: gray('gray-700');
      }
    }

    tbody tr {
      border-top-width: 0;

      td {
        border: 0;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}


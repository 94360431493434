

























































































































































@import '../../utilities/config';
@import '../../utilities/table/table';

$header-background: rgb(235, 235, 237);
$border-color: rgb(201, 201, 201);

.building-ready-to-submit {
  display: inline-block;
  margin-left: 5px;
  height: 24px;
  width: 24px;
  background-image: url(../../assets/check_green.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  padding: 0;
  vertical-align: text-bottom;
}

.building-draft {
  display: inline-block;
  margin-left: 5px;
  height: 24px;
  width: 24px;
  background-image: url(../../assets/progress_bar_gray.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0;
  vertical-align: text-bottom;
}

.submit-button {
  margin-left: 10px;
}

.building-list {
  &__table {
    border-collapse: separate;
    border-spacing: 0;

    .center-align {
      text-align: center;
    }

    .right-align {
      text-align: right;
    }

    tr {
      border: 2px solid $border-color;
    }

    thead tr {
      background-color: $header-background;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      th {
        border: 0;
        color: gray('gray-700');
      }
    }

    tbody tr {
      border-top-width: 0;

      td {
        border: 0;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}


@import '../config';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/custom-forms';
@import 'node_modules/bootstrap/scss/grid';

label {
  color: gray('gray-750');
  font-size: 15px;

  &:not(.form-check-label) {
    font-weight: 500;
  }

  &.custom-control-label {
    font-weight: 300;
    font-size: 16px;
  }

  .optional {
    font-weight: 300;
    font-size: 0.81rem;
  }
}

.invalid-feedback {
  display: block;
  &.is-invalid {
    display: block;
  }
}

.is-invalid-block {
  .form-control {
    border-color: theme-color('danger');
  }
}

.checkbox-desc {
  display: block;
  color: gray('gray-750');
  font-size: 13px;
  padding-left: 1.25rem;
  font-style: italic;
}
.input-desc {
  display: block;
  color: gray('gray-750');
  font-size: 13px;
  font-style: italic;
  margin-top: 8px;
  &__bolder {
    font-weight: 400;
  }
}
.form-inline {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 0;
}
.form-inline + .form-inline {
  margin-right: 0;
}
.form {
  position: relative;
  padding-top: 80px;

  &__header {
    height: 100px;
    background-color: gray('white');
    border-bottom: 1px solid gray('gray-150');
    display: flex;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 10;
    transition: height 0.5s ease;

    &__back {
      align-items: center;
      display: flex;

      & > .form-group {
        margin: 0;
      }

      &__link {
        display: inline-block;
        position: relative;
        font-size: 15px;
        font-weight: 500; //font-weight: 400;;
        color: gray('gray-400');
        text-decoration: none;
        padding: 0;
        padding-left: 20px;

        &.btn {
          padding: 0 0 0 20px;
          display: inline-block;
          font-weight: 500; //font-weight: 400;;
          border: 0;
        }

        &::before {
          content: '';
          display: block;
          background-image: url(../../assets/close_gray.svg);
          background-size: contain;
          background-repeat: no-repeat;
          height: 11px;
          width: 11px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
        }

        &:hover {
          color: gray('gray-700');
          text-decoration: none;

          &::before {
            background-image: url(../../assets/close_dark_gray.svg);
          }
        }

        &--arrow {
          &::before {
            background-image: url(../../assets/arrow_left_gray.svg);
            background-size: contain;
            content: '';
            display: inline-block;
            height: 8px;
            width: 8px;
            //margin-right: 10px;
            //transform: translateY(-1px);
          }
          &:hover {
            &::before {
              background-image: url(../../assets/arrow_left_dark_gray.svg);
            }
          }
        }
      }
    }
    &__btn {
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    &__main {
      align-items: center;
      display: flex;
    }

    &__title {
      font-weight: 500;
      color: gray('gray-700');
      line-height: 1.25em;
      font-size: 17px;
      transition: font-size 0.5s ease;
      margin: 0;
    }

    &__utils {
      align-items: center;
      justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;

      .form-group {
        margin: 0;
      }
    }

    &--sticky {
      height: 100px;
      box-shadow: 0px 7px 20px 0px rgba(gray('black'), 0.08);

      .form__header__title {
        font-size: 17px;
      }
    }
  }

  &__body {
    padding-bottom: 40px;
    //padding-top: 120px;
    padding-top: 40px;
    background-color: gray('gray-100');

    &--alt {
      padding-bottom: 40px;
      padding-top: 40px;
      border-top: 1px solid gray('gray-150');
    }
  }

  &__container {
    & > .form__subtitle:first-child {
      margin-top: 0;
    }
  }

  &__footer {
    padding-bottom: 40px;
    padding-top: 40px;
    border-top: 1px solid gray('gray-150');
    &__btn {
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &__buttons {
    &__left {
      .form-group {
        margin: 0;
      }
    }
    &__right {
      .form-group {
        margin: 0;
      }
    }
  }

  &__col {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__btn {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    .form-group {
      margin-left: 10px;
    }
    &--left {
      justify-content: flex-start;
      .form-group {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 500;

    &--bolder {
      font-weight: bold;
    }
  }

  &__subtitle {
    font-size: 21px;
    font-weight: 500;
    color: gray('gray-700');
    border-bottom: 4px solid gray('gray-150');
    padding-bottom: 8px;
    margin-top: 25px;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .optional {
      font-size: 15px;
      font-weight: 400;
    }
  }

  &__error-notice {
    font-weight: 500; //font-weight: 400;;
    color: theme-color('danger');
  }

  &__smalltitle {
    font-size: 16px;
    font-weight: 500; //font-weight: 400;;
    color: gray('gray-700');
    border-bottom: 1px solid gray('gray-150');
    padding-bottom: 8px;
    margin-top: 25px;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  .form {
    &__header {
      width: calc(100% - 66px);
    }
    &__buttons {
      //margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__left {
        .form-group {
          margin: 0;
        }
      }
      &__right {
        align-items: center;
        //justify-content: flex-end;
        display: flex;
        flex-wrap: wrap;

        .form-group {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: breakpoint-min('md')) {
  .form {
    padding-top: 150px;
    &__header {
      align-items: center;
      height: 170px;
      &__btn {
        margin-right: 0;
        margin-left: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        /*margin-right: 10px;*/
        /*&::last-child {
          margin-right: 0px;
        }*/
      }
      &__utils {
        //justify-content: flex-end;
      }
      .form__header__title {
        font-size: 30px;
      }

      &--sticky {
        height: 100px;
        box-shadow: 0px 7px 20px 0px rgba(gray('black'), 0.08);

        .form__header__title {
          font-size: 17px;
        }
      }
    }
    &__body {
      //padding-top: 190px;
    }
  }
}

@media screen and (min-width: breakpoint-min('lg')) {
  .form {
    &__header {
      width: calc(100% - 240px);
    }
  }
}

























































@import '../../utilities/config';

.contracts {
  margin: 16px 0;

  & * {
    align-items: center;
  }

  &__item {
    border: 1px solid gray('gray-150');
    border-top-width: 0;
    padding: 10px 16px;
    display: flex;

    &:first-child {
      border-top-width: 1px;
    }

    &:hover {
      background-color: gray('gray-100');
    }

    &__name {
      flex-grow: 1;
      font-size: 17px;
      font-weight: 500;
    }

    &__date {
      flex-shrink: 0;
      margin-right: 20px
    }
  }
}

















































































@import '../../utilities/form/form';

.page__header__title {
  &::after {
    background-image: url(../../assets/home_gray.svg);
  }
}

.project-details {
  margin-bottom: 1rem;
}

.external-link-green {
  display: inline-block;
  margin-left: 5px;
  height: 24px;
  width: 24px;
  background-image: url(../../assets/external_link_green.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 60%;
  padding: 0;
  vertical-align: text-bottom;
}

